import React, { useRef, useEffect, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { Box, IconButton, InputAdornment, TextField, CircularProgress, MenuItem, Select, FormControl, InputLabel, ThemeProvider, CssBaseline } from '@mui/material';
import Container from "@mui/material/Container";
import SearchIcon from '@mui/icons-material/Search';
import defaultTheme from '../../utilities/styleUtilities/DefaultTheme';
import { restClient } from '@polygon.io/client-js';

const rest = restClient(process.env.REACT_APP_POLYGONIO_CLIENT_HP_KEY);

export default function USEquitiesDataChart() {
    const [input, setInput] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [tickerSymbol, setTickerSymbol] = useState(() => localStorage.getItem('storedTickerSymbol') || 'TSLA');
    const [interval, setInterval] = useState(() => localStorage.getItem('storedInterval') || 'day');
    const [chartType, setChartType] = useState(() => localStorage.getItem('storedChartType') || 'candlestick');

    const chartContainerRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const lineSeriesRef = useRef(null);
    const candlestickSeriesRef = useRef(null);
    const cacheExpirationTime = 60 * 60 * 1000; // 1 hour in milliseconds

    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            setIsLoading(true);

            const retryFetch = async (fn, retries, delay) => {
                try {
                    const response = await fn();
                    return response;
                } catch (error) {
                    if (retries > 0) {
                        await new Promise(resolve => setTimeout(resolve, delay));
                        return retryFetch(fn, retries - 1, delay); // Retry
                    }
                    throw error;
                }
            };

            try {
                const today = new Date();
                let startDate;
                const endDate = today.toISOString().split('T')[0];

                if (interval === 'minute') {
                    startDate = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));
                } else if (interval === 'hour') {
                    startDate = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));
                } else {
                    startDate = new Date(today.getTime() - (732 * 24 * 60 * 60 * 1000));
                }

                startDate = startDate.toISOString().split('T')[0];

                const cacheKey = `fitData-${tickerSymbol}-${interval}-${startDate}-${endDate}`;
                const cacheTimestampKey = `${cacheKey}-timestamp`;
                const cachedData = localStorage.getItem(cacheKey);
                const cacheTimestamp = localStorage.getItem(cacheTimestampKey);

                if (cachedData && cacheTimestamp) {
                    const timestamp = parseInt(cacheTimestamp, 10);
                    if (Date.now() - timestamp < cacheExpirationTime) {
                        setData(JSON.parse(cachedData));
                        setIsLoading(false);
                        return;
                    }
                }

                const fetchAggregates = () => rest.stocks.aggregates(tickerSymbol, 1, interval, startDate, endDate);

                const result = await retryFetch(fetchAggregates, 3, 60000); // Retry up to 3 times with 1-minute delay

                let fetchedData = result.results.map(result => ({
                    time: Math.floor(result.t / 1000),
                    open: result.o,
                    high: result.h,
                    low: result.l,
                    close: result.c,
                    value: result.c
                }));

                fetchedData = fetchedData.sort((a, b) => a.time - b.time);
                setData(fetchedData);
                localStorage.setItem(cacheKey, JSON.stringify(fetchedData));
                localStorage.setItem(cacheTimestampKey, Date.now().toString());
            } catch (error) {
                console.error('An error occurred while fetching data:', error);
                setError('An error occurred while fetching data. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [tickerSymbol, interval]);

    useEffect(() => {
        if (data.length && chartContainerRef.current && !chartInstanceRef.current) {
            chartInstanceRef.current = createChart(chartContainerRef.current, {
                width: 650,
                height: 240,
                layout: {
                    backgroundColor: '#FFFFFF',
                },
                timeScale: {
                    timeVisible: interval !== 'day',
                    secondsVisible: interval === 'minute',
                },
                rightPriceScale: {
                    borderColor: '#cccccc',
                },
                grid: {
                    vertLines: {
                        color: '#e0e0e0',
                    },
                    horzLines: {
                        color: '#e0e0e0',
                    },
                },
                crosshair: {
                    mode: 1,
                },
                priceLineVisible: true,
            });

            return () => {
                if (chartInstanceRef.current) {
                    chartInstanceRef.current.remove();
                    chartInstanceRef.current = null;
                }
            };
        }
    }, [data, interval]);

    useEffect(() => {
        if (chartInstanceRef.current) {
            if (lineSeriesRef.current) {
                try {
                    chartInstanceRef.current.removeSeries(lineSeriesRef.current);
                } catch (error) {
                    console.error("Error removing line series:", error);
                }
                lineSeriesRef.current = null;
            }
            if (candlestickSeriesRef.current) {
                try {
                    chartInstanceRef.current.removeSeries(candlestickSeriesRef.current);
                } catch (error) {
                    console.error("Error removing candlestick series:", error);
                }
                candlestickSeriesRef.current = null;
            }

            if (chartType === 'line') {
                lineSeriesRef.current = chartInstanceRef.current.addLineSeries({
                    color: '#2962FF',
                    lineWidth: 2,
                });
                lineSeriesRef.current.setData(data.map(d => ({ time: d.time, value: d.value })));
            } else if (chartType === 'candlestick') {
                candlestickSeriesRef.current = chartInstanceRef.current.addCandlestickSeries({
                    upColor: '#26a69a',
                    downColor: '#ef5350',
                    borderVisible: true,
                    wickUpColor: '#26a69a',
                    wickDownColor: '#ef5350',
                });
                candlestickSeriesRef.current.setData(data.map(d => ({
                    time: d.time,
                    open: d.open,
                    high: d.high,
                    low: d.low,
                    close: d.close,
                })));
            }
        }
    }, [data, chartType]);

    useEffect(() => {
        localStorage.setItem('storedTickerSymbol', tickerSymbol);
        localStorage.setItem('storedInterval', interval);
        localStorage.setItem('storedChartType', chartType);
    }, [tickerSymbol, interval, chartType]);

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleSend = () => {
        if (input.trim() !== "") {
            setTickerSymbol(input.toUpperCase());
            setInput("");
        }
    };

    const handleIntervalChange = (event) => {
        setInterval(event.target.value);
    };

    const handleChartTypeChange = (event) => {
        setChartType(event.target.value);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Container>
                <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                    <TextField
                        label={tickerSymbol}
                        variant="outlined"
                        size="small"
                        sx={{ mr: 1 }}
                        value={input}
                        onChange={handleInputChange}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                handleSend(event);
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="search" onClick={handleSend}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            sx: {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                },
                                '& input': {
                                    color: 'white',
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: 'white',
                            }
                        }}
                    />
                    <FormControl size="small" sx={{ mr: 1, minWidth: 120 }}>
                        <InputLabel id="interval-select-label">Interval</InputLabel>
                        <Select
                            labelId="interval-select-label"
                            value={interval}
                            onChange={handleIntervalChange}
                            label="Interval"
                        >
                            <MenuItem value="minute">Minute</MenuItem>
                            <MenuItem value="hour">Hour</MenuItem>
                            <MenuItem value="day">Day</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl size="small" sx={{ minWidth: 120 }}>
                        <InputLabel id="chart-type-select-label">Chart Type</InputLabel>
                        <Select
                            labelId="chart-type-select-label"
                            value={chartType}
                            onChange={handleChartTypeChange}
                            label="Chart Type"
                        >
                            <MenuItem value="line">Line</MenuItem>
                            <MenuItem value="candlestick">Candlestick</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                {isLoading ? (
                    <CircularProgress sx={{ mt: 10 }} />
                ) : (
                    <div ref={chartContainerRef} />
                )}
            </Container>
        </ThemeProvider>
    );
}
