const holidays = [
    '2024-01-01', // New Year's Day
    '2024-01-15', // Martin Luther King, Jr. Day
    '2024-02-19', // Presidents Day
    '2024-03-29', // Good Friday
    '2024-05-27', // Memorial Day
    '2024-06-19', // Juneteenth National Independence Day
    '2024-07-04', // Independence Day
    '2024-09-02', // Labor Day
    '2024-11-28', // Thanksgiving Day
    '2024-12-25', // Christmas Day
    '2025-01-01', // New Year's Day
    '2025-01-20', // Martin Luther King, Jr. Day
    '2025-02-17', // Presidents Day
    '2025-04-18', // Good Friday
    '2025-05-26', // Memorial Day
    '2025-06-19', // Juneteenth National Independence Day
    '2025-07-04', // Independence Day
    '2025-09-01', // Labor Day
    '2025-11-27', // Thanksgiving Day
    '2025-12-25', // Christmas Day
    '2026-01-01', // New Year's Day
    '2026-01-19', // Martin Luther King, Jr. Day
    '2026-02-16', // Presidents Day
    '2026-04-03', // Good Friday
    '2026-05-25', // Memorial Day
    '2026-06-19', // Juneteenth National Independence Day
    '2026-07-03', // Independence Day
    '2026-09-07', // Labor Day
    '2026-11-26', // Thanksgiving Day
    '2026-12-25', // Christmas Day
    '2027-01-01', // New Year's Day
    '2027-01-18', // Martin Luther King, Jr. Day
    '2027-02-15', // Presidents Day
    '2027-03-26', // Good Friday
    '2027-05-31', // Memorial Day
    '2027-06-18', // Juneteenth National Independence Day
    '2027-07-05', // Independence Day observed
    '2027-09-06', // Labor Day
    '2027-11-25', // Thanksgiving Day
    '2027-12-24', // Christmas Day observed
  ];

  const partialHolidays = [
    '2024-07-03', // Day before Independence Day
    '2024-11-29', // Day after Thanksgiving
    '2024-12-24', // Christmas Eve
    '2025-07-03', // Day before Independence Day
    '2025-11-28', // Day after Thanksgiving
    '2025-12-24', // Christmas Eve
    '2026-07-03', // Day before Independence Day
    '2026-11-27', // Day after Thanksgiving
    '2026-12-24', // Christmas Eve
    '2027-07-02', // Day before Independence Day
    '2027-11-26', // Day after Thanksgiving
    '2027-12-24', // Christmas Eve
  ];

export { holidays, partialHolidays };